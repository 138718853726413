import Image from 'next/image';

import Page from 'components/shared/layout/Page';

import robotSrc from 'public/error/robot.png';

const ErrorPage = () => {
  return (
    <Page>
      <section className="w-full min-h-[100vh] flex flex-col justify-center items-center flex-grow text-black dark:text-white p-4 text-center">
        <h1 className="text-[34px] font-bold mb-6 leading-tight">
          Sorry, something is wrong here!
        </h1>

        <p className="text-2xl font-thin">
          An error occurred while loading this page.
        </p>

        <div className="relative w-full max-w-[460px] aspect-square mt-6">
          <Image
            alt="Robot"
            src={robotSrc}
            layout={'fill'}
            loading="lazy"
            placeholder="blur"
          />
        </div>
      </section>
    </Page>
  );
};

export default ErrorPage;
