import { PropsWithChildren } from 'react';

import { motion } from 'framer-motion';
import { useFadeInTransition } from 'src/hooks/transitions';

const Page = ({ children = [] }: PropsWithChildren<{}>): JSX.Element => {
  const { container, item } = useFadeInTransition();

  return (
    <>
      <motion.div variants={container} initial="hidden" animate="show">
        {(Array.isArray(children) ? children : [children]).map(
          (child, index) => (
            <motion.section key={index} variants={item}>
              {child}
            </motion.section>
          ),
        )}
      </motion.div>
    </>
  );
};

export default Page;
